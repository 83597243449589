import React, { createContext, useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFavoriteDocIds, getSearchDocuments, fecthDirectfromDb } from '../API/dashboard';
import { getPublishedDocumentTypes } from '../API/document-type';
import CONSTANTS from '../constants';
import { useGAEvents } from '../hooks/useGoogleAnalytics';
import {
    IAdditionalSearch,
    IDocTypeSearch,
    IOptionValue,
    IPaginationData,
    IStringObject,
    ILevelOption
} from '../interfaces';
import { ISearch } from '../interfaces/dashboard';
import { IFilterConfig } from '../interfaces/filters';
import { useAuthDataContext } from './user-auth-provider';



const intialLevelOptions : ILevelOption = {
    value : '',
    label : ''
}

const levelOptions : ILevelOption[] = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: 'All', label: 'All' }
];

export const DEFAULT_DOC_TYPE: IDocTypeSearch = { label: 'All Types', value: '', fields: [] };

export const DEFAULT_ADVANCED_SEARCH: IAdditionalSearch[] = [
    {
        displayName: 'Document Number',
        name: 'documentNumber',
        exact: true,
        // ! MEMO-FIX : setting default value
        value: '',
    },
    {
        displayName: 'Document Title',
        name: 'title',
        exact: false,
        value: '',
    },
    {
        displayName: 'Author',
        name: 'owner.name',
        exact: false,
        value: '',
    },
    {
        displayName: 'Manager',
        name: 'manager',
        exact: false,
        value: '',
    },
    // {
    //     displayName: 'State',
    //     name: 'state',
    //     exact: false,
    //     value: '',
    // },
];

const DEFAULT_PAGINATION: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

interface ISearchContext {
    showSearch: boolean;
    docTypesLoading: boolean;
    filtersConfig: IFilterConfig[];
    isLoading: boolean;
    searchDocuments: ISearch[];
    favoriteDocIds: number[];
    searchPagination: IPaginationData;
    firstLoad: boolean;
    searchString: string;
    docType: IDocTypeSearch;
    advancedFilters: IAdditionalSearch[];
    filters: IAdditionalSearch[];
    searchInAttachment: boolean;
    setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
    onSearch: () => void;
    advancedSearchChange: (index: number, value: string) => void;
    setDocTypesLoading: React.Dispatch<React.SetStateAction<boolean>>;
    filterSearch: (queryParams: IStringObject) => void;
    docTypeSelect: IOptionValue | undefined;
    setdocTypeSelect: React.Dispatch<React.SetStateAction<IOptionValue | undefined>>;
    sortOption: string;
    setSortOption: React.Dispatch<React.SetStateAction<string>>;
    level: ILevelOption | undefined;
    setLevel: React.Dispatch<React.SetStateAction<ILevelOption | undefined>>;
    sortOrder: string;
    setSortOrder: React.Dispatch<React.SetStateAction<string>>;
    setSearchPagination: React.Dispatch<React.SetStateAction<IPaginationData>>;
    fetchFavoriteDocIds: () => Promise<void>;
    setSearchDocuments: React.Dispatch<React.SetStateAction<ISearch[]>>;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    setDocType: React.Dispatch<React.SetStateAction<IDocTypeSearch>>;
    setAdvancedFilters: React.Dispatch<React.SetStateAction<IAdditionalSearch[]>>;
    onClear: () => void;
    onReset: () => void;
    deleteAdvanceFilter: (field: string) => void;
    deleteFilter: (field: string) => void;
    deletedFilter: string;
    setSearchInAttachment: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAuthor: IOptionValue | undefined;
    selectedManager: IOptionValue | undefined;
    selectedState: IOptionValue | undefined;
    setselectedAuthor: React.Dispatch<React.SetStateAction<IOptionValue | undefined>>;
    setselectedManager: React.Dispatch<React.SetStateAction<IOptionValue | undefined>>;
    setSelectedState: React.Dispatch<React.SetStateAction<IOptionValue | undefined>>;
    toList: IOptionValue[] | undefined;
    setToList: React.Dispatch<React.SetStateAction<IOptionValue[] | undefined>>;
    ccList: IOptionValue[] | undefined;
    setCcList: React.Dispatch<React.SetStateAction<IOptionValue[] | undefined>>;
    levelOptions : ILevelOption[];
}

const SearchContext = createContext<ISearchContext>({
    showSearch: false,
    docTypesLoading: false,
    filtersConfig: [],
    favoriteDocIds: [],
    isLoading: false,
    searchDocuments: [],
    searchPagination: DEFAULT_PAGINATION,
    firstLoad: true,
    searchString: '',
    docType: DEFAULT_DOC_TYPE,
    advancedFilters: DEFAULT_ADVANCED_SEARCH,
    filters: [],
    searchInAttachment: false,
    setShowSearch: () => {},
    onSearch: () => {},
    advancedSearchChange: () => {},
    setDocTypesLoading: () => {},
    filterSearch: () => {},
    docTypeSelect: undefined,
    setdocTypeSelect: () => {},
    sortOption: 'default',
    setSortOption: () => {},
    level: undefined,
    setLevel: () => {},
    sortOrder: 'asc',
    setSortOrder: () => {},
    setSearchPagination: () => {},
    setSearchDocuments: () => {},
    fetchFavoriteDocIds: () =>
        new Promise((resolve) => {
            resolve();
        }),
    setDocType: () => {},
    setSearchString: () => {},
    setAdvancedFilters: () => {},
    onClear: () => {},
    onReset: () => {},
    deleteAdvanceFilter: () => {},
    deleteFilter: () => {},
    deletedFilter: '',
    setSearchInAttachment: () => {},
    selectedAuthor: undefined,
    selectedManager: undefined,
    selectedState: undefined,
    setselectedAuthor: () => {},
    setselectedManager: () => {},
    setSelectedState: () => {},
    toList: undefined,
    setToList: () => {},
    ccList: undefined,
    setCcList: () => {},
    levelOptions: [],
});

// eslint-disable-next-line max-lines-per-function
const SearchProvider: React.FC = (props: unknown) => {
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuthDataContext();

    const [showSearch, setShowSearch] = useState(false);
    const [docTypesLoading, setDocTypesLoading] = useState(false);
    const [searchInAttachment, setSearchInAttachment] = useState(false);

    const [filtersConfig, setFilterConfig] = useState<IFilterConfig[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchDocuments, setSearchDocuments] = useState<ISearch[]>([]);
    const [searchPagination, setSearchPagination] = useState(DEFAULT_PAGINATION);
    const [docTypeSelect, setdocTypeSelect] = useState<IOptionValue | undefined>();
    const [sortOption, setSortOption] = useState('approvedOn');
    const [level, setLevel] = useState<ILevelOption | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState('desc');
    const [favoriteDocIds, setFavoriteDocIds] = useState<number[]>([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [filters, setFilters] = useState<IAdditionalSearch[]>([]);
    const [advancedFilters, setAdvancedFilters] =
        useState<IAdditionalSearch[]>(DEFAULT_ADVANCED_SEARCH);
    const [searchString, setSearchString] = useState('');
    const [docType, setDocType] = useState<IDocTypeSearch>(DEFAULT_DOC_TYPE);
    const [selectedAuthor, setselectedAuthor] = useState<IOptionValue | undefined>();
    const [selectedManager, setselectedManager] = useState<IOptionValue | undefined>(); 
    const [selectedState, setSelectedState] = useState<IOptionValue | undefined>();
    const [toList, setToList] = useState<IOptionValue[] | undefined>();
    const [ccList, setCcList] = useState<IOptionValue[] | undefined>();
    // !MEMO-FIX : removing MEMO- from search filter
    const [memoFilters, setMemoFilters] = useState<IAdditionalSearch[]>([]);
    const [deletedFilter, setDeletedFilter] = useState('');
    const fetchFavoriteDocIds = async () => {
        if (user.id && user.id !== -1) {
            const res = await getFavoriteDocIds();
            if (res?.apiStatus === 'SUCCESS') {
                setFavoriteDocIds(res.documentIds);
            }
        }
    };
    const { triggerEvent } = useGAEvents();

    // eslint-disable-next-line max-lines-per-function
    const fetchSearchDocuments = async () => {
        if (user.id && user.id !== -1) {
            triggerEvent('DMS_SEARCH');
            setIsLoading(true);
            const selectedFilters: IAdditionalSearch[] = filters.filter((a) => a.value);
            const trimmedFilters = advancedFilters.map((currFilter) => ({
                ...currFilter,
                value: currFilter.value.trim(),
            }));
            if (selectedFilters.length > 0) {
                setDeletedFilter('');
            }
            setAdvancedFilters(trimmedFilters);
            let selectedAdvancedFilters: IAdditionalSearch[] = trimmedFilters.filter(
                (a) => a.value,
            );
            // !MEMO-FIX : removing MEMO- from search filter
            selectedAdvancedFilters = selectedAdvancedFilters.filter(
                (a) => !(a.name === 'documentNumber' && a.value === 'MEMO-'),
            );
            // note: this has to be changed when configuration changes
            const [docTypeFilters] = selectedFilters.filter((f) => f.name === 'index.keyword');
            const documentType = docTypeFilters ? docTypeFilters.value : (docType?.value as string);

            const advancedSearch = [
                ...selectedFilters.filter((f) => f.name !== 'index.keyword'),
                ...selectedAdvancedFilters,
            ];
            if (advancedSearch.length === 0 && !searchString && !docType.value) {
                setFirstLoad(true);
                setSearchPagination({
                    ...searchPagination,
                    totalCount: DEFAULT_PAGINATION.totalCount,
                });
            } else {
                const response = await getSearchDocuments(
                    searchString,
                    advancedSearch,
                    searchInAttachment,
                    docTypeSelect,
                    sortOption,
                    sortOrder,
                    level,
                    documentType,
                    searchPagination.pageSize,
                    searchPagination.pageIndex * searchPagination.pageSize
                );
                if (response?.apiStatus === 'SUCCESS') {
                    setFirstLoad(false);
                    setSearchDocuments(response.searchDocuments);
                    setFilterConfig(response.facets);
                    setSearchPagination({ ...searchPagination, totalCount: response.totalCount });
                } else {
                    toast.error(CONSTANTS.ERROR_406);
                }
            }
            setIsLoading(false);
        }
    };

    const deleteFilter = (field: string) => {
        const updatedFilters =
            filters && filters.length > 0
                ? filters.map((filer) => {
                      if (filer.displayName === field) {
                          // eslint-disable-next-line no-param-reassign
                          filer.value = '';
                      }
                      return filer;
                  })
                : [];
        setFilters(updatedFilters);
        setDeletedFilter(field);
    };

    const onReset = () => {
        setFilters([]);
        setSearchPagination({
            ...searchPagination,
            pageSize: DEFAULT_PAGINATION.pageSize,
            pageIndex: DEFAULT_PAGINATION.pageIndex,
        });
        // fetchSearchDocuments();
    };

    // eslint-disable-next-line max-lines-per-function
    const onSearch = async () => {
        const query = searchString;
        const whitespaceSeparated = query.split(" ");
        let docNumberInQuery = "";
        /* eslint-disable no-plusplus */
        for (let i = 0; i < whitespaceSeparated.length; i++) {
            const currStr = whitespaceSeparated[i];
            const hyphenSeparated = currStr.split("-");
            const len = hyphenSeparated.length;
            const lastStr = hyphenSeparated[len-1];
            if (len > 1 && lastStr.length >= 5) {
                // for migrated files, removing first 0
                if((hyphenSeparated[0].toUpperCase() === 'FILE' && lastStr.length === 6 && lastStr[0] === '0')) {
                    hyphenSeparated[len-1] = lastStr.substring(1);
                }
                hyphenSeparated[0] = hyphenSeparated[0].toUpperCase();
                docNumberInQuery = hyphenSeparated.join("-");
                break;
            }
        }
        if(docNumberInQuery && !query.toLowerCase().includes(" to ")) {
            const response = await fecthDirectfromDb(
                'documentNumber',
                docNumberInQuery.trim()
            );
            if (response?.apiStatus === 'SUCCESS' && response.docId) {
                history.push({
                    pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${response.docId}&prev_page_type=SEARCH`,
                });
                return;
            } 
            if (response === undefined){
                setSortOption('Best Match');
            }
        } else {
            setSortOption('approvedOn')
        }
        const selectedAdvancedFiltersIntial: IAdditionalSearch[] = advancedFilters.filter(
            (a) => a.value,
        );
        /* select only document number field */
        if (
            selectedAdvancedFiltersIntial.length === 1 &&
            selectedAdvancedFiltersIntial[0].name === 'documentNumber' &&
            selectedAdvancedFiltersIntial[0].value !== ''
        ) {
            const response = await fecthDirectfromDb(
                selectedAdvancedFiltersIntial[0].name,
                selectedAdvancedFiltersIntial[0].value.trim(),
            );
            if (response?.apiStatus === 'SUCCESS') {
                history.push({
                    pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${response.docId}&prev_page_type=SEARCH`,
                });
            } else if (response === undefined) {
                history.push({ pathname: `/${CONSTANTS.RELATIVE_PATHS.search.Url}` });
                setFirstLoad(false);
                setIsLoading(false);
                onReset();
                setSearchDocuments([]);
            }
        } else {
            if (location.pathname !== '/search') {
                history.push({ pathname: `/${CONSTANTS.RELATIVE_PATHS.search.Url}` });
            }
            if (filters.length > 0) {
                setDeletedFilter('All');
            }
            setFilters([]);
            setSearchPagination({
                ...searchPagination,
                pageSize: DEFAULT_PAGINATION.pageSize,
                pageIndex: DEFAULT_PAGINATION.pageIndex,
            });
        }
    };

    // ! MEMO-FIX : This is added to bypass default doc type as MEMO
    const updateAdvancedFilters = async () => {
        if (user.id && user.id !== -1) {
            const res = await getPublishedDocumentTypes('MEMO');
            if (res?.apiStatus === 'SUCCESS') {
                const { docTypeCard } = res;
                // eslint-disable-next-line no-restricted-syntax
                for (const d of docTypeCard) {
                    const fields: IAdditionalSearch[] = d.fields.map((f) => ({
                        name: `data.${f.name}`,
                        displayName: f.displayName,
                        exact: false,
                        value: '',
                    }));
                    setMemoFilters(fields);
                    setAdvancedFilters([...DEFAULT_ADVANCED_SEARCH, ...fields]);
                }
            }
        }
    };

    const deleteAdvanceFilter = (field: string) => {
        const updatedAdvancedFilters =
            advancedFilters && advancedFilters.length > 0
                ? advancedFilters.map((flter) => {
                      if (flter.displayName === field) {
                          // eslint-disable-next-line no-param-reassign
                          flter.value = '';
                      }
                      return flter;
                  })
                : [];
        setAdvancedFilters(updatedAdvancedFilters);
        if (field === 'CC') {
            setCcList(undefined);
        } else if (field === 'To') {
            setToList(undefined);
        } else if (field === 'Author') {
            setselectedAuthor(undefined);
        }
        fetchSearchDocuments();
    };

    // for clearing advance search
    const onClear = () => {
        setDeletedFilter('All');
        setFilters([]);
        // ! MEMO-FIX : This is added to bypass default doc type as MEMO
        setAdvancedFilters([...DEFAULT_ADVANCED_SEARCH, ...memoFilters]);
        // setAdvancedFilters(DEFAULT_ADVANCED_SEARCH);
        setSearchString('');
        setFilterConfig([]);
        setSearchInAttachment(false);
        setDocType(DEFAULT_DOC_TYPE);
        setselectedAuthor(undefined);
        setselectedManager(undefined);
        setSelectedState(undefined);
        setToList(undefined);
        setCcList(undefined);
        setLevel(undefined);
    };

    // eslint-disable-next-line max-lines-per-function
    const filterSearch = (queryParams: IStringObject) => {
        const queryParamKeys = Object.keys(queryParams);
        const advanced: IAdditionalSearch[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const param of queryParamKeys) {
            if (
                param === 'Date Range' ||
                param === 'Custom Date RangeEnd' ||
                param === 'Custom Date RangeStart'
            ) {
                advanced.push({
                    displayName: param,
                    exact: false,
                    facet: false,
                    name: param,
                    value: queryParams[param],
                });
            } else if (queryParams[param]) {
                advanced.push({
                    displayName: param,
                    exact: true,
                    facet: true,
                    name: `${param}.keyword`,
                    value: queryParams[param],
                });
            }
        }
        /* if(filters.length===0){
            setSearchPagination({
                ...searchPagination,
                pageSize: DEFAULT_PAGINATION.pageSize,
                pageIndex: DEFAULT_PAGINATION.pageIndex,
            });
        } */
        if (JSON.stringify(advanced) !== JSON.stringify(filters)) {
            setSearchPagination({
                ...searchPagination,
                pageSize: DEFAULT_PAGINATION.pageSize,
                pageIndex: DEFAULT_PAGINATION.pageIndex,
            });
        }
        setFilters(advanced);
    };

    const advancedSearchChange = (index: number, value: string) => {
        let advancedSearch = advancedFilters;
        // ! MEMO-FIX : if condition should be removed to remove default first 4 character check for memo
        /* if (index === 0 && (value === 'MEMO' || value.length <= 4)) {
            advancedSearch = [
                ...advancedFilters.slice(0, index),
                { ...advancedFilters[index], value: '' },
                ...advancedFilters.slice(index + 1),
            ];
        } else {
            advancedSearch = [
                ...advancedFilters.slice(0, index),
                { ...advancedFilters[index], value },
                ...advancedFilters.slice(index + 1),
            ];
        } */
        advancedSearch = [
            ...advancedFilters.slice(0, index),
            { ...advancedFilters[index], value },
            ...advancedFilters.slice(index + 1),
        ];
        setAdvancedFilters(advancedSearch);
    };

    useEffect(() => {
        fetchSearchDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchPagination.pageIndex,
        searchPagination.pageSize,
        filters,
        docTypeSelect,
        sortOption,
        sortOrder,
    ]);


    useEffect(() => {
        fetchFavoriteDocIds();
        updateAdvancedFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // to open search by default on search page
    useEffect(() => {
        if (location.pathname === '/search') {
            setShowSearch(true);
        } else {
            setShowSearch(false);
        }
    }, [location.pathname]);

    return (
        <SearchContext.Provider
            value={{
                showSearch,
                docTypesLoading,
                filtersConfig,
                favoriteDocIds,
                isLoading,
                searchDocuments,
                searchPagination,
                firstLoad,
                searchString,
                docType,
                advancedFilters,
                filters,
                searchInAttachment,
                setShowSearch,
                onSearch,
                advancedSearchChange,
                setDocTypesLoading,
                filterSearch,
                docTypeSelect,
                setdocTypeSelect,
                sortOption,
                setSortOption,
                sortOrder,
                setSortOrder,
                setSearchPagination,
                setSearchDocuments,
                fetchFavoriteDocIds,
                setDocType,
                setSearchString,
                setAdvancedFilters,
                onReset,
                onClear,
                deleteAdvanceFilter,
                deleteFilter,
                deletedFilter,
                setSearchInAttachment,
                selectedAuthor,
                setselectedAuthor,
                selectedState,
                setSelectedState,
                toList,
                setToList,
                ccList,
                setCcList,
                selectedManager,
                setselectedManager,
                level,
                setLevel,
                levelOptions,
            }}
            {...props}
        />
    );
};

export const useSearch = (): ISearchContext => useContext(SearchContext);

export default SearchProvider;
