/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import ViewAttachments from '../../components/attachments/view/ViewAttachments';
import DocumentAction from '../../components/document-action/DocumentAction';
import ImportantFields from '../../components/important-fields/ImportantFields';
import Reviewers from '../../components/reviewers/Reviewers';
import { ExternalStageCard, StageCard } from '../../components/work-flow-panel/WorkFlowPanel';
import TaskDetailProvider, { useTaskDetailContext } from '../../contexts/task-details-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import DocComments from '../../widgets/doc-comment/DocComments';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';

import './TaskDetails.scss';
import { useDocumentContext } from '../../contexts/document-provider';
import ImportantFieldsForTask from '../../components/important-fields/ImportantFieldsForTask';
import ChecklistComponent from '../../components/checklist/checklistComponent';
import Toggle from '../../components/toggle/Toggle';
import ViewLinkedFiles from '../../components/input-fields/file-type-docs/ViewLinkedFiles';
import ActivitySection from '../../components/activity-section/ActivitySection';
import { updateApprovalStatus } from '../../API/document';
import Button from '../../components/button/Button';

// eslint-disable-next-line max-lines-per-function
const TaskDetails: React.FC = () => {
    const { user } = useAuthDataContext();
    const {
        docInfo,
        userType,
        fields,
        fieldsData,
        reviewers,
        approvers,
        approversDispatch,
        externalApprovers,
        documentId,
        activeDocVersion,
        isTaskActive,
        setActiveDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        canViewTaskDetails,
        hideUsers,
        collectComment,
        collectCommentRejection,
        collectCommentWithdraw,
        delegatorInfo,
        docTypeInfo,
        setHideUsers,
        taskId,
        attachmentConfig,
        isDocFetched,
        approverStatus,
        isGroupMember,
        accessibleByCurrentUser,
        publishOnceApproved,
        isSunsetEnabled,
        taskFieldsData,
        taskDocumentDispatch,
        activeVersionOwnerInfo,
        isLeadershipForm,
        activeVersionApprovalDeadline,
        setActiveVersionApprovalDeadline,
        isEndDateAsked,
        isTravelAuthorizationForm,
        externalApproversDispatch,
        saveDeadlineData,
        checklist,
        activeApprovalStatusId,
        
    } = useTaskDetailContext();
    const {
        activeDocVersionId,
        relationArr,
        setRelationArr,
        latestrelationArr,
        setlatestrelationArr,
        existingContract
    } = useDocumentContext();
    const docTypeInfo1 = useTaskDetailContext().docTypeInfo;
    const [unsavedComment, setUnsavedComment] = useState('');
    const setUnsavedCommentFunction = (comment: string) => {
        setUnsavedComment(comment);
    };
    const isDocusignTriggered = approvers[0]?.approvers[0]?.docuSignTriggered
    ? approvers[0].approvers[0].docuSignTriggered
    : false;
    const [activeTab, setActiveTab] = useState('Doc_Details');
    const [activeTabInHistory, setActiveTabInHistory] = useState('General');
    const [filesData] = taskFieldsData.filter((a) => a.name === 'file');
    const [addNewStageLoading,setAddNewStageLoading] = useState(false);

    const history = useHistory();
    const [routeToDocumentDetails, setRouteToDocumentDetails] = useState(false);
    useEffect(() => {
        if (routeToDocumentDetails) {
            toast.warn("You don't have the access to the others task page URL, redirecting to accessible page");
            history.push(`/document-details?doc_id=${documentId}`);
        }
    }, [routeToDocumentDetails, history, documentId]);

    useEffect(() => {
        const shouldRedirect = !(canViewTaskDetails || isGroupMember);

        // Only set the state to true if the conditions are met
        if (shouldRedirect) {
            setRouteToDocumentDetails(true);
        }
    }, [
        canViewTaskDetails,
        isGroupMember
    ])

    const addNewStage = async () => {
        setAddNewStageLoading(true);
        const toastId = toast.loading('adding new stage in process ...');
        const newApprovers = approvers;
        const stageToUpdate = newApprovers.find(stage => stage.disable === true);
        if (stageToUpdate) {
            stageToUpdate.disable = false;
        }
        approversDispatch({
            type: 'update',
            value: newApprovers,
        });
        await updateApprovalStatus(activeApprovalStatusId,reviewers,approvers,docInfo.author.id,activeVersionApprovalDeadline,existingContract,documentId);
        toast.dismiss(toastId);
        toast.success('added stage successfully');
        setAddNewStageLoading(false);
    }

    return (
        <>
            {canViewTaskDetails || isGroupMember ? (
                <>
                    <DocumentInfo
                        pageType="TASK_DETAILS"
                        docInfo={docInfo}
                        documentId={documentId}
                        activeDocVersion={activeDocVersion}
                        latestDocVersionId={activeDocVersionId}
                        docVersions={[]}
                        setActiveDocVersion={setActiveDocVersion}
                        setDocInfo={setDocInfo}
                        onFavoriteClickHAndler={fetchDocumentVersion}
                        descError={false}
                        titleError={false}
                        effectiveFromError={false}
                        effectivePeriodError={false}
                        sunsetPeriodError={false}
                        docTypeInfo={docTypeInfo}
                        delegatorInfo={delegatorInfo}
                        publishOnceApproved={publishOnceApproved}
                        isSunsetEnabled={isSunsetEnabled}
                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                        activeVersionApprovalDeadline={activeVersionApprovalDeadline}
                        setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                        isEndDateAsked={isEndDateAsked}
                        relationArr={relationArr}
                        setRelationArr={setRelationArr}
                        latestrelationArr={latestrelationArr}
                        setlatestrelationArr={setlatestrelationArr}
                    />
                    <div className="task-details__section">
                        {docTypeInfo.storeAndShowActivity && <div className="tab">
                            <button
                                id="Doc_Details"
                                type="button"
                                className={`${activeTab === 'Doc_Details' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Doc_Details')}
                            >
                                <span className="name">
                                    {docTypeInfo.isDCO ? 'Affected Documents' : 'Details'}
                                </span>
                            </button>
                            <button
                                type="button"
                                id="Doc_History"
                                className={`${activeTab === 'Doc_History' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Doc_History')}
                            >
                                <span className="name">History</span>
                            </button>
                        </div>}
                        {activeTab === 'Doc_Details' && (
                            <div className={`${docTypeInfo.storeAndShowActivity ? 'main-section storeAndShowActivity' : 'main-section'}`}>
                                <div className="task-details">
                                    <h3>{docTypeInfo.isDCO ? 'Affected Documents' : 'Document Details'}</h3>
                                    <ImportantFieldsForTask
                                        isDisabled
                                        fields={fields}
                                        fieldsData={taskFieldsData}
                                        documentDispatch={taskDocumentDispatch}
                                    />
                                </div>
                                {docTypeInfo.allowFileTypeAttachment && filesData ?
                                    <ViewLinkedFiles filesData={filesData} /> :
                                    <ViewAttachments
                                        activeDocVersion={activeDocVersion.value as number}
                                        attachmentConfig={attachmentConfig}
                                        isDocFetched={isDocFetched}
                                        allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                        checklist = {checklist}
                                    />
                                }
                                <ChecklistComponent isDisabled={true} />
                                {approvers.length > 0 && approverStatus !== 'REJECTED' && (
                                    <div className="approvers">
                                        <div className="approval-header">
                                            {approvers.length > 0 &&
                                                approvers[0].approvers.length > 0 && (
                                                    <h3>
                                                        {docTypeInfo1.approversSectionTitle
                                                            ? docTypeInfo1.approversSectionTitle
                                                            : 'Approvers'}
                                                    </h3>
                                                )}
                                            {docTypeInfo.isDCO &&
                                            docInfo.docState === 'UNDER_REVIEW' &&
                                            isGroupMember ? (
                                                <div>
                                                <span data-tip="" data-for="tooltip">
                                                    <Button
                                                        disabled={
                                                            !approvers[approvers.length - 1]
                                                                ?.disable
                                                        }
                                                        isLoading={addNewStageLoading}
                                                        className=""
                                                        onClick={() => {
                                                            addNewStage();
                                                        }}
                                                        type={undefined}
                                                    >
                                                        + Add New Stage
                                                    </Button>
                                                </span>
                                                <ReactTooltip
                                                    className="tooltip-docinfo"
                                                    id="tooltip"
                                                    effect="solid"
                                                    place="right"
                                                    offset={{ top: 10 }}
                                                >
                                                    <div>
                                                        Maximum of 3 additional stages can be added
                                                    </div>
                                                </ReactTooltip>
                                            </div>
                                            ) : null}
                                        </div>
                                        <div className="approvers-tab tab-data">
                                            { /* eslint-disable max-lines-per-function */
                                                approvers.map((approver, index) => (
                                                    <>
                                                        {((isGroupMember && docTypeInfo.isDCO) || approver.approvers.length > 0) ? (
                                                            <>
                                                                {docTypeInfo1.isContract && approver.scopeType === 'groupBased' ? (
                                                                    <>
                                                                        <StageCard
                                                                            stageIndex={index}
                                                                            error={false}
                                                                            stageData={approver}
                                                                            approversDispatch={approversDispatch}
                                                                            setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                                            isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            key={index}
                                                                            cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                                            docTypeInfo={docTypeInfo}
                                                                            docInfo={docInfo}
                                                                            activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                                            saveFieldsData={saveDeadlineData}
                                                                        />
                                                                        {externalApprovers?.length ? <ExternalStageCard externalApprovers={externalApprovers} isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)} docTypeInfo={docTypeInfo} externalApproversDispatch={externalApproversDispatch} docInfo={docInfo} /> : null}
                                                                    </>
                                                                ) : (!approver.disable &&
                                                                    <StageCard
                                                                        stageIndex={index}
                                                                        error={false}
                                                                        stageData={approver}
                                                                        approversDispatch={approversDispatch}
                                                                        setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                                        isEditable={(isGroupMember && approvers[0]?.approvers[0]?.state === 'UNDER_REVIEW' && (docTypeInfo.isDCO || (docTypeInfo.isContract && !isDocusignTriggered)))}
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        key={index}
                                                                        cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                                        docTypeInfo={docTypeInfo}
                                                                        docInfo={docInfo}
                                                                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                                        saveFieldsData={saveDeadlineData}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </>
                                                ))
                                                /* eslint-enable max-lines-per-function */
                                            }
                                        </div>
                                    </div>
                                )}
                                {reviewers.length > 0 && (
                                    <div className="reviewers">
                                        <h3>Reviewers</h3>
                                        <Reviewers isEditable={false} />
                                    </div>
                                )}
                                {userType !== 'OTHER' && isDocFetched && (
                                    <div className="comments">
                                        <DocComments
                                            activeDocVersion={activeDocVersion.value as number}
                                            setUnsavedCommentFunction={setUnsavedCommentFunction}
                                            allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                            userType={userType}
                                            pageType="TASK_DETAILS"
                                            approvers={approvers}
                                        />
                                    </div>
                                )}
                                {isTaskActive && (
                                    <DocumentAction
                                        docState={docInfo.docState}
                                        documentId={documentId}
                                        userType={userType}
                                        activeDocumentVersion={activeDocVersion.value as number}
                                        hideUsers={hideUsers}
                                        collectComment={collectComment}
                                        collectCommentRejection={collectCommentRejection}
                                        collectCommentWithdraw={collectCommentWithdraw}
                                        setHideUsers={setHideUsers}
                                        taskId={taskId}
                                        assigneeAndCreatorSame={(!isLeadershipForm && !isTravelAuthorizationForm) && user.id === docInfo.author.id && !docTypeInfo.isDCO}
                                        unsavedComment={unsavedComment}
                                        setUnsavedCommentFunction={setUnsavedCommentFunction}
                                        render={docInfo.render}
                                        assignee={docInfo.assignee}
                                        accessibleByCurrentUser={accessibleByCurrentUser}
                                        autoRenew={docInfo.autoRenew}
                                        docInfo={docInfo}
                                        setDocInfo={setDocInfo}
                                        isEndDateAsked={isEndDateAsked}
                                        isDocusignTriggered={approvers[0]?.approvers[0]?.docuSignTriggered ? approvers[0].approvers[0].docuSignTriggered : false}
                                    />
                                )}
                            </div>
                        )}

                        {activeTab === 'Doc_History' && docTypeInfo.storeAndShowActivity && (
                            <div className='history-tab'>
                                <div className="doc-history">
                                    <div className='doc-history-tab'>
                                        <button
                                            type="button"
                                            id="General"
                                            className={`${activeTabInHistory === 'General' ? 'active' : ''}`}
                                            onClick={() => setActiveTabInHistory('General')}
                                        >
                                            <span className="name">General</span>
                                        </button>
                                        {
                                            docTypeInfo.allowDCO && (<button
                                                type="button"
                                                id="Revisions"
                                                className={`${activeTabInHistory === 'Revisions' ? 'active' : ''}`}
                                                onClick={() => setActiveTabInHistory('Revisions')}
                                            >
                                                <span className="name">Revisions</span>
                                            </button>
                                            )}
                                    </div>
                                    {
                                        activeTabInHistory === 'General' && (
                                            <ActivitySection documentId={documentId} docTypeInfo={docTypeInfo} />
                                        )}
                                </div>
                            </div>
                        )}

                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const TaskDetailsPage: React.FC = () => (
    <div className="task-details">
        <TaskDetailProvider>
            <TaskDetails />
        </TaskDetailProvider>
    </div>
);

export default TaskDetailsPage;
